//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import HeaderSM from "./component/common/header/headerSM";
import Sidebar from "./component/common/sidebar/sidebar";
import SidebarSM from "./component/common/sidebar/sidebarsm";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import { useGetNavitem } from "./appProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { VApplication } from "../../../application";

import {
    useViewport,
    ViewportProvider,
} from "@vlinder-web/viewport-provider-react";

const loginService = VApplication.getInstance().getSync(
    "services.AuthService"
) as AuthService;



const _MENU_ITEMS = [
    {
        eventKey: "organization",
        title: "Profile",
        icon: <i class="icon-home"></i>,
        path: "/profile",
        type: "sub",
        active: true,
        bookmark: false,
        children: [
            { title: "Account", type: "sub" },
            {
                title: "Profile",
                type: "link",
                path: `/profile`,
            },
        ],
    },

    {
        eventKey: "Onboard Users",
        title: "Onboard",
        icon: <i className="icon-user"></i>,
        path: "/onboard",
        type: "sub",
        active: false,
        children: [
            { title: "Onboard Users", type: "sub" },
            {
                title: "Users",
                type: "link",
                path: `/listed-user`,
            },
            {
                title: "Onboard",
                type: "link",
                path: `/onboard`,
            },
        ],
    },

    {
        eventKey: "report",
        title: "Report",
        icon: <i className="icon-book"></i>,
        path: "/report-table",
        type: "sub",
        active: false,
        children: [
            { title: "Report", type: "sub" },
            {
                title: "Report Table",
                type: "link",
                path: `/report-table`,
            },
        ],
    },

    {
        eventKey: "Application Fee",
        title: "Application Fee",
        icon: <i className="icon-user"></i>,
        path: "/application-fee",
        type: "sub",
        active: false,
        children: [
            { title: "Application Fee", type: "sub" },
            {
                title: "Application Fee",
                type: "link",
                path: `/application-fee`,
            },
        ],
    },
];

const _getChildren = (childItems: any) => {
    const _children = childItems?.map((item) => {
        if (item?.title !== "Wallet") {
            return {
                ...item,
            };
        }
    });
    return _children;
};


const _getMenuItems =(userDept?:string, ) =>{
    console.log('inside application user :', userDept);
    console.log("inside", userDept != "master")
    if((userDept === "master")){
        return _MENU_ITEMS;
    }
    else if(userDept === "doft" ){
        const _menuItems = [];
        _menuItems.push(
            {
                eventKey: "organization",
                title: "Profile",
                icon: <i class="icon-home"></i>,
                path: "/profile",
                type: "sub",
                active: true,
                bookmark: false,
                children: [
                    { title: "Account", type: "sub" },
                    {
                        title: "Profile",
                        type: "link",
                        path: `/profile`,
                    },
                ],
            },
            {
                eventKey: "statement",
                title: "Statement",
                icon: <i className="icon-book"></i>,
                path: "/doft-statement",
                type: "sub",
                active: false,
                children: [
                    { title: "Statement", type: "sub" },
                    {
                        title: "View Statement",
                        type: "link",
                        path: `/doft-statement`,
                    },
                ],
            },
            {
                eventKey: "application",
                title: "Application",
                icon: <i className="icofont icofont-list"></i>,
                path: "/doft-application",
                type: "sub",
                active: false,
                children: [
                    { title: "Application", type: "sub" },
                    {
                        title: "View Application",
                        type: "link",
                        path: `/doft-application`,
                    },
                ],
            },
        )
        return _menuItems;
    }
    else if(userDept === "cro" ){
        const _menuItems = [];
        _menuItems.push(
            {
                eventKey: "organization",
                title: "Profile",
                icon: <i class="icon-home"></i>,
                path: "/profile",
                type: "sub",
                active: true,
                bookmark: false,
                children: [
                    { title: "Account", type: "sub" },
                    {
                        title: "Profile",
                        type: "link",
                        path: `/profile`,
                    },
                ],
            },
            {
                eventKey: "application",
                title: "Application",
                icon: <i className="icofont icofont-list"></i>,
                path: "/cro-application",
                type: "sub",
                active: false,
                children: [
                    { title: "Application", type: "sub" },
                    {
                        title: "View Application",
                        type: "link",
                        path: `/cro-application`,
                    },
                ],
            },
      
        )
        return _menuItems;
    }

    else if(userDept === "oc" ){
        const _menuItems = [];
        _menuItems.push(
            {
                eventKey: "organization",
                title: "Profile",
                icon: <i class="icon-home"></i>,
                path: "/profile",
                type: "sub",
                active: true,
                bookmark: false,
                children: [
                    { title: "Account", type: "sub" },
                    {
                        title: "Profile",
                        type: "link",
                        path: `/profile`,
                    },
                ],
            },
            {
                eventKey: "application",
                title: "Application",
                icon: <i className="icofont icofont-list"></i>,
                path: "/oc-application",
                type: "sub",
                active: false,
                children: [
                    { title: "Application", type: "sub" },
                    {
                        title: "View Application",
                        type: "link",
                        path: `/oc-application`,
                    },
                ],
            },
      
        )
        return _menuItems;
    }
}

const getAuthSerivice = () => {
    const vapp = VApplication.getInstance();
    const [userName, setUserName] = useState<string>();
    const authSerivice = vapp.getSync("services.AuthService") as AuthService;
    return authSerivice;
};

const App = ({ children, ...props }) => {
    const MENU_ITEMS = _getMenuItems(props?.userDept);
    // const MENU_ITEMS = _MENU_ITEMS;
    const { bp, width } = useViewport();
    const [userName, setUserName] = useState<string>();
    // const {getUserProfile, signOut} = useAuth();
    const history = useHistory();
    // useEffect(()=>{
    //     loginService.getUserProfile().then(res=>{
    //         console.log(`user profile in bugsbunny`, res);
    //         setUserName(res?.fullName);
    //     })
    // },[])

    //     useEffect(()=>{
    //         window.location.reload()
    // },[])

    const onLogoutClick = () => {
        console.log("logout button pressed");
        loginService.signOut();
        window.open("/", "_self");
        // window.location.reload();
    };

    // let _sideBarItems = [];
    // Array.isArray(MENU_ITEMS) &&
    //   MENU_ITEMS.map((item) => {
    //     let _tempNav;
    //     context?.payload?.navItems?.map((ele) => {
    //       if (item?.eventKey === ele?.key && ele?.enable) {
    //         _sideBarItems.push({
    //           ...item,
    //         });
    //       }
    //     });
    //   });

    const getSidebar = () => {
        if (bp === "xs" || bp === "sm") {
            return <SidebarSM menuItems={MENU_ITEMS} />;
        } else {
            return <Sidebar menuItems={MENU_ITEMS} />;
        }
    };

    const getHeader = () => {
        if (bp === "xs" || bp == "sm") {
            return (
                <HeaderSM
                    menuItems={MENU_ITEMS}
                    searchbar={false}
                    userName={userName}
                    onLogoutClick={onLogoutClick}
                />
            );
        } else {
            return (
                <Header
                    menuItems={MENU_ITEMS}
                    searchbar={false}
                    userName={userName}
                    onLogoutClick={onLogoutClick}
                />
            );
        }
    };
    return (
        <Fragment>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    {getHeader()}
                    {getSidebar()}
                    <Rightsidebar />
                    <div className="page-body">{children}</div>
                    <Footer />
                    {/* <ThemeCustomize/> */}
                </div>
            </div>
            <ToastContainer
                autoClose={2000}
                position="top-center"
                className="toast-container"
                toastClassName="dark-toast"
            />
        </Fragment>
    );
};

export default App;