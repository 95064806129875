import React, { Component } from "react";
import {lazyWithRetry } from "./ComponentLoader";

const DashboardPage = lazyWithRetry(() => import("../../../../pages/Dashboard"));
const AdminPage = lazyWithRetry(() => import("../../../../pages/Admin"));
const UserPage = lazyWithRetry(() => import("../../../../pages/User"));
const OnboardFormPage = lazyWithRetry(() => import("../../../../pages/OnboardForm"));
const ApplicationFeePage = lazyWithRetry(() => import("../../../../pages/ApplicationFee"));
const ReportTablePage = lazyWithRetry(() => import("../../../../pages/ReportTable"));
const ApplicationFormPage = lazyWithRetry(() => import("../../../../pages/ApplicationForm"));
const DisableUserDetailPage = lazyWithRetry(() => import("../../../../pages/DisableUserDetails"));
const ReportDetailsPage = lazyWithRetry(() => import("../../../../pages/ReportDetails"));
const DoftStatmentPage = lazyWithRetry(() => import("../../../../pages/DoftStatement"));
const DoftApplicationPage = lazyWithRetry(() => import("../../../../pages/DoftApplication"));
const DoftApplicationDetailsPage = lazyWithRetry(() => import("../../../../pages/DoftApplicationDetails"));
const CroApplicationPage = lazyWithRetry(() => import("../../../../pages/CroApplication"));
const OcApplicationDetailsPage = lazyWithRetry(() => import("../../../../pages/OcApplicationDetails"));
const OcApplicationPage = lazyWithRetry(() => import("../../../../pages/OcApplication"));
const CroApplicationDetailsPage = lazyWithRetry(() => import("../../../../pages/CroApplicationDetails"));




export const routes = [

    // {path:"/", Component: AdminPage},
    {path:"/profile", Component: AdminPage},
    {path:"/listed-user", Component: UserPage},
    {path:"/onboard", Component: OnboardFormPage},
    {path:"/application-fee", Component: ApplicationFeePage},
    {path:"/report-table", Component: ReportTablePage},
    {path:"/application-fee-form", Component: ApplicationFormPage},
    {path:"/disable-user-detail", Component: DisableUserDetailPage},
    {path:"/report-details", Component: ReportDetailsPage},
    {path:"/doft-statement", Component: DoftStatmentPage},
    {path:"/doft-application", Component: DoftApplicationPage},
    {path:"/doft-application-details", Component: DoftApplicationDetailsPage},
    {path:"/cro-application", Component: CroApplicationPage},
    {path:"/oc-application-details", Component: OcApplicationDetailsPage},
    {path:"/oc-application", Component: OcApplicationPage},
    {path:"/cro-application-details", Component: CroApplicationDetailsPage},









    // { path: "/", Component: AssetsPage },
    
    
    // { path: "/my-assets", Component: MyAssetsPage },
    // { path: "/my-assets/asset", Component: MyAssetDetailPage },
    // { path: "/my-wallet", Component: MyWalletPage },
    
];
