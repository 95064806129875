import React from "react";

export const data = [
    {
        id: 0,
        name: "Johan Deo",
        thumb: "user/1.jpg",
        status: "  Out is my favorite",
        reply: <i className="fa fa-share font-success" />,
        lastSeenDate: "0",
        online: true,
        typing: true,
    },
    {
        id: 1,
        name: "Vincent Porter",
        thumb: "user/2.png",
        status: "  Change for anyone.",
        reply: <i className="fa fa-share font-success" />,
        lastSeenDate: "Last seen today 15:24",
        online: false,
        typing: false,
    },
    {
        id: 2,
        name: "Aiden Chavez",
        thumb: "user/3.png",
        status: "  first bun like a sun.",
        reply: <i className="fa fa-reply font-danger" />,
        lastSeenDate: "Last seen today 13:24",
        online: false,
        typing: false,
    },
    {
        id: 3,
        name: "Prasanth Anand",
        thumb: "user/4.jpg",
        status: "  Out is my favorite",
        reply: <i className="fa fa-reply font-danger" />,
        lastSeenDate: "Last seen today 17:42",
        online: false,
        typing: false,
    },
    {
        id: 4,
        name: "Venkata Satyamu",
        thumb: "user/5.jpg",
        status: "  Change for anyone.",
        reply: <i className="fa fa-reply font-danger" />,
        lastSeenDate: "Last seen today 18:00",
        online: false,
        typing: false,
    },
    {
        id: 5,
        name: "Ginger Johnston",
        thumb: "user/6.jpg",
        status: "  first bun like a sun.",
        reply: <i className="fa fa-share font-success" />,
        lastSeenDate: "Last seen today 22:24",
        online: false,
        typing: false,
    },
    {
        id: 6,
        name: "Kori Thomas",
        thumb: "user/7.jpg",
        status: "  Change for anyone.",
        reply: <i className="fa fa-share font-success" />,
        lastSeenDate: "Last seen today 00:24",
        online: false,
        typing: false,
    },
    {
        id: 7,
        name: "Vincent Porter",
        thumb: "user/8.jpg",
        status: "  first bun like a sun.",
        reply: <i className="fa fa-reply font-danger" />,
        lastSeenDate: "Last seen yesterday 10:50",
        online: false,
        typing: false,
    },
];

export default data;
