//@ts-nocheck
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./index.min.css";
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./App";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./router/route";
import Loader from "./component/common/loader/loader";
import ConfigDB from "./data/customizer/config";

export const Root = (props) => {
    const [anim, setAnim] = useState("");
    const animation =
        localStorage.getItem("animation") || ConfigDB.data.router_animation;
    const abortController = new AbortController();

    useEffect(() => {
        setAnim(animation);
        const layout = localStorage.getItem("layout_version");
        document.body.classList.add(layout);
        const color = localStorage.getItem("color");
        // document
        //   .getElementById("color")
        //   .setAttribute(
        //     "href",
        //     `${process.env.PUBLIC_URL}/assets/css/${color}.css`
        //   );
        console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
        console.disableYellowBox = true;
        return function cleanup() {
            abortController.abort();
        };

        // eslint-disable-next-line
    }, []);
const _getRoutes=() => {
    return (
        <>
        {routes.map(({ path, Component }) => (
         
            <Route key={path} exact path={path}>
                {({ match }) => (
                    <CSSTransition
                        in={match != null}
                        // timeout={{ enter: 1, exit: 100 }}
                        classNames={anim}
                        unmountOnExit
                    >
                        <div>
                            <Component />
                        </div>
                    </CSSTransition>
                )}
            </Route>
        ))}
        </>
        
    )
}
    return (
        
        <Fragment>
            <Provider store={store}>
                <Suspense fallback={<Loader />}>
                    <BrowserRouter basename={`/dashboard`}>
                        <Switch>
                            <Fragment>
                                <App {...props}>
                                    <Route
                                        exact
                                        path="/"
                                        render={() => {
                                            return <Redirect to="/" />;
                                        }}
                                    />
                                    <TransitionGroup>
                                        {_getRoutes()}
                                    </TransitionGroup>
                                </App>
                            </Fragment>
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </Provider>
        </Fragment>
    );
};
